@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.m2-get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $warn: map.get($config, warn);

  .card-btn-container {
    .expire {
      background: map.get($background, 'hover');
      &--soon {
        color: map.get($warn, 'A100');
      }
      &--expired {
        color: map.get($warn, 500);
      }
    }
  }

  .payment-method {
    .no-payment-selected {
      background: map.get($background, 'hover');
      color: map.get($warn, 500);
    }
  }
}

@mixin typography($typography-config) {
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($typography-config);
  }
}
