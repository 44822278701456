@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.m2-get-color-config($theme);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);
  $warn: map.get($config, warn);

  .retry-notification-container {
    background: map.get($warn, 500);

    .text {
      color: map.get(map.get($warn, 'contrast'), 500);
    }
  }
}

@mixin typography($typography-config) {
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($typography-config);
  }
}
