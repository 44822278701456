@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.m2-get-color-config($theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $warn: map.get($config, warn);
  $background: map.get($config, background);
  $foreground: map.get($config, 'foreground');

  $is-dark: map.get($config, is-dark);

  $racing-green-50: map.get($primary, 50);
  $racing-green-100: map.get($primary, 100);
  $racing-green-500: map.get($primary, 500);

  $viggo-green-700: map.get($accent, 700);

  $warn-500: map.get($warn, 500);

  $foreground-slider-off: map.get($foreground, 'slider-off');

  .ride-status-icon {
    .pickup-in-progress,
    .claimed {
      color: $racing-green-500;
    }

    .in-progress {
      color: $viggo-green-700;
    }

    .completed {
      color: $viggo-green-700;
    }

    .expired {
      color: $warn-500;
    }

    .cancelled {
      color: map.get($foreground, 'secondary-text');
    }
  }
}

@mixin typography($typography-config) {
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($theme);
  }
}
