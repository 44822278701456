@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.m2-get-color-config($theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $warn: map.get($config, warn);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);

  $racing-green-500: map.get($primary, 500);

  .vig-caption {
    color: map.get($foreground, 'secondary-text');
  }

  .is-scheduled {
    color: $racing-green-500;
  }

  .ride-details-payment-info {
    background: map.get($background, 'hover');
  }

  .tile-buttons__button {
    background: map.get($background, 'hover');
    color: $racing-green-500;
  }

  .viggo-green-700 {
    color: map.get($accent, 700);
  }

  .red-500 {
    color: map.get($warn, 500);
  }

  .list-button.package {
    border-color: map.get($foreground, 'divider');
  }

  .retry-container {
    background: map.get($warn, 500);
  }

  .retry-with-different-card {
    border-color: map.get($foreground, 'base') !important;
  }
}

@mixin typography($typography-config) {
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
