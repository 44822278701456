@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.m2-get-color-config($theme);
  $warn: map.get($config, warn);

  .disabled-card-payments-notification {
    background-color: map.get($warn, 'A100');

    .vig-subtitle-1,
    .vig-body-1 {
      color: map.get(map.get($warn, 'contrast'), 'A100');
    }
  }
}

@mixin typography($typography-config) {
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($typography-config);
  }
}
