@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.m2-get-color-config($theme);
  $primary: map.get($config, primary);

  $racing-green-50: map.get($primary, 50);
  $racing-green-500: map.get($primary, 500);

  .container {
    .table-container {
        .pending-invite {
            background-color: $racing-green-50;
        }
    }
    .domains-container {
        background-color: $racing-green-50;

        .domain-chip {
            background-color: $racing-green-500;
            span {
                color: $racing-green-50;
            }
        }
    }
  }
}

@mixin typography($typography-config) {}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($theme);
  }
}