@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.m2-get-color-config($theme);
  $accent: map.get($config, accent);

  $viggo-green-700: map.get($accent, 700);

  .navbar-container {
    .active-link .mat-icon {
      color: mat.m2-get-color-from-palette($accent, text);
    }

    .active-link {
      svg {
        color: $viggo-green-700
    }
  }
}
}

@mixin typography($typography-config) {
  .navbar-container {
    .active-link .mdc-list-item__primary-text {
      @include mat.m2-typography-level($typography-config, subtitle-1);
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config !=null {
    @include typography($typography-config);
  }
}