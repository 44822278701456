@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.m2-get-color-config($theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  $racing-green-50: map.get($primary, 50);

  .content {
    .info-container {
        background-color: $racing-green-50;
    }
  }

}

@mixin typography($typography-config) {}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($theme);
  }
}