@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.m2-get-color-config($theme);
  $accent: map.get($config, accent);
  $foreground: map.get($config, foreground);

  .pickup-prefix {
    color: map.get($accent, 700);
  }

  .muted {
    color: var(--mdc-outlined-text-field-label-text-color);
  }

  .info-icon {
    color: map.get($foreground, 'hint-text');
  }
}

@mixin typography($typography-config) {
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
