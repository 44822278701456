@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.m2-get-color-config($theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $foreground: map.get($config, foreground);
  $background: map.get($config, background);

  .selected {
    background-color: map.get($background, 'selected-button');
  }
}

@mixin typography($typography-config) {
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
