@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.m2-get-color-config($theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);

  $racing-green-50: map.get($primary, 50);
  $viggo-green-700: map.get($accent, 700);

  .mapboxgl-map {
    height: 100%;
    .eta-marker {
      background-color: $viggo-green-700;
      border-radius: 40px;
      padding: 0 0.5em;
      color: $racing-green-50;
    }
  }
}

@mixin typography($typography-config) {}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
