@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.m2-get-color-config($theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $warn: map.get($config, warn);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  $racing-green-50: map.get($primary, 50);
  $racing-green-100: map.get($primary, 100);
  $racing-green-500: map.get($primary, 500);

  $viggo-green-700: map.get($accent, 700);

  $warn-500: map.get($warn, 500);

  .ride-status-text {
    .looking-for-driver {
      background-color: map.get($background, 'disabled-list-option');
      color: $racing-green-500;
    }

    .pickup-in-progress {
      background-color: $racing-green-500;
      color: $racing-green-50;
    }

    .in-progress {
      background-color: $racing-green-100;
      color: $racing-green-500;
    }

    .completed,
    .ended {
      color: $viggo-green-700;
    }

    .expired {
      color: $warn-500;
    }

    .cancelled {
      color: map.get($foreground, 'secondary-text');
    }

    .scheduled,
    .claimed {
      color: $racing-green-500;
    }
  }
}

@mixin typography($typography-config) {
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
