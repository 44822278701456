@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.m2-get-color-config($theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $warn: map.get($config, warn);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  $racing-green-50: map.get($primary, 50);

  $viggo-green-700: map.get($accent, 700);

  $warn-500: map.get($warn, 500);

  .content {
    .info-container {
        background-color: $racing-green-50;
        .download {
            color: $viggo-green-700;
        }
    }

    .error {
        color: $warn-500;
    }
  }

}

@mixin typography($typography-config) {}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($theme);
  }
}