@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.m2-get-color-config($theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  $racing-green-50: map.get($primary, 50);
  $viggo-green-700: map.get($accent, 700);

  .tile {
    border-color: map.get($foreground, 'divider');
    background-color: map.get($background, 'background');

    .eta {
      background-color: $viggo-green-700;
      .vig-subtitle-1 {
        color: $racing-green-50;
      }
    }

    &:hover {
      background-color: map.get($background, 'hover');
      cursor: pointer;
    }

    &.selected {
      background-color: map.get($background, 'hover');
      border-color: $viggo-green-700;
    }
  }

  .no-packages {
    border-color: map.get($foreground, 'divider');

    .title {
      color: map.get($accent, 'text');
    }
  }
}

@mixin typography($typography-config) {
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}
