// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use './all-themes' as app;
@use './design-system' as vig;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

$b2b-web-light-theme: vig.define-light-theme();
$b2b-web-dark-theme: vig.define-dark-theme();

// Include densities and typographies
@include mat.all-component-densities($b2b-web-light-theme);
@include mat.all-component-typographies(vig.$typography-config);
@include app.all-component-typographies(vig.$typography-config);

// Include light theme styles for core and each component used in your app.
.light-mode {
  @include mat.all-component-bases($b2b-web-light-theme);
  @include mat.all-component-colors($b2b-web-light-theme);
  @include app.all-component-colors($b2b-web-light-theme);
  @include vig.color-hierarchy($b2b-web-light-theme);
  @include vig.light-theme-overrides($b2b-web-light-theme);
}

// Include dark theme styles for core and each component used in your app.
.dark-mode {
  @include mat.all-component-bases($b2b-web-dark-theme);
  @include mat.all-component-colors($b2b-web-dark-theme);
  @include app.all-component-colors($b2b-web-dark-theme);
  @include vig.color-hierarchy($b2b-web-dark-theme);
}

// Other includes
@include vig.typography-hierarchy();
@include vig.fonts('/assets/fonts');
@include vig.fix-stepper-icon-font-size-pading();
@include vig.snackbar-classes();
@include vig.table-classes();

// Global css
html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.pointer {
  cursor: pointer;
}

.mtop4 {
  margin-top: 0.25rem;
}

.mtop8 {
  margin-top: 0.5rem;
}

.mbot8 {
  margin-bottom: 0.5rem;
}

.mtop16 {
  margin-top: 1rem;
}

.mbot16 {
  margin-bottom: 1rem;
}

.mtop24 {
  margin-top: 1.5rem;
}

.mbot24 {
  margin-bottom: 1.5rem;
}

.mbot40 {
  margin-bottom: 2.5rem;
}

.mtop40 {
  margin-top: 2.5rem;
}

mat-stepper[disable-steps] .mat-step-header {
  pointer-events: none !important;
  cursor: not-allowed;
}

mat-stepper[hide-header] .mat-horizontal-stepper-header-container {
  display: none;
}

mat-stepper[hide-labels] .mat-step-label {
  display: none;
}

.progress-bar {
  position: fixed !important;
}

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
}

.btn-spinner circle {
  stroke: white;
}

.underline {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}
