@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.m2-get-color-config($theme);
  $accent: map.get($config, accent);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  $background-background: map.get($background, background);
  $foreground-divider: map.get($foreground, 'divider');

  .toolbar {
    background-color: $background-background;
  }

  .mat-divider {
    background: $background-background;
  }

  .mat-drawer {
    background-color: $background-background;
  }
}

@mixin typography($typography-config) {}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($typography-config);
  }
}